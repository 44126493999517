import { useCallback } from "react";
import ReactGA from 'react-ga';

import { ReactComponent as Logo } from "../../images/logo.svg";
import styles from "./Page.module.scss";

export const Page = () => {
  const ctaHandler = useCallback(() => {
    ReactGA.event({
      category: 'Landing Page',
      action: 'Click lp',
      label: 'Contact us'
    });
  }, []);

  return (
    <section className={styles.page}>
      <div className={styles.backdrop}>
        <div className={styles.content}>
          <Logo width={180} fill="white" />
          <h1 className={styles.title}>Encore un peu de patience !</h1>
          <p className={styles.paragraph}>Vous pourrez bientôt configurer les bons plans de votre concession.</p>
          <p className={styles.paragraph}>Notre équipe travaille actuellement sur cette nouvelle fonctionnalité. En attendant, pour ajouter ou supprimer un bon plan : vous pouvez faire votre demande par email à la <strong>Relation Client Pro</strong>.</p>
          <a className={styles.cta} onClick={ctaHandler} href="mailto:relationclientpro@roole.fr?subject=Demande modification Bon Plan">Faire une demande par email</a>
        </div>
      </div>
    </section>
  );
}
