import { useEffect } from "react";
import ReactGA from "react-ga";

import { Page as Home } from "./pages/Home/Page";

export const App = () => {
  useEffect(() => {
    ReactGA.initialize("UA-79154517-4");
  }, []);

  return <Home />;
};
